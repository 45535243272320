import React, { useState } from 'react';
import { IoCloseCircle } from 'react-icons/io5';
import { FaRankingStar } from 'react-icons/fa6';
import { Typography, Box, Button } from '@mui/material';
import { changeHideIconRating } from '@/redux/reducers/auth';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import RatingScreenCardapio from '../ratingScreenCardapio';
import { FaStar } from 'react-icons/fa';
import { MdStars } from 'react-icons/md';

export default function IconRatingComponent() {
	const { iconRating, client } = useAppSelector(state => state.auth);
	const dispatch = useAppDispatch();
	const [ratingScreenOpen, setRatingScreenOpen] = useState(false);

	if (!iconRating) return <></>;

	return (
		<Box className="boxConteinerRatingIcon">
			<Box className="iconCloseRating">
				<IoCloseCircle onClick={() => dispatch(changeHideIconRating())} />
			</Box>
			<Button className="boxRatingIcon" onClick={() => setRatingScreenOpen(true)} sx={{ background: '#fff', '&:hover': { background: '#fff' } }}>
				<Box className="btnRating">
					<MdStars style={{ fontSize: '1.5rem', color: '#' + client?.config_cliente?.cor }} />
					<Typography className="typoAvalieIcon">Avalie</Typography>
				</Box>
			</Button>

			<RatingScreenCardapio open={ratingScreenOpen} setOpen={setRatingScreenOpen} isPage={true} />
		</Box>
	);
}
